<template>
    <svg
        class="h-auto stroke-current text-theme-green"
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.875 1.125L6.125 9.875L1.75 5.5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
